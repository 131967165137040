import { ApolloClient, InMemoryCache, from } from "@apollo/client";
import { split } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";
import { createNetworkStatusNotifier } from "react-apollo-network-status";
import { SyncLoader } from "react-spinners";

const { link, useApolloNetworkStatus } = createNetworkStatusNotifier();

const httpLink = createUploadLink({
  uri: `//${process.env.REACT_APP_SERVER_IP}`,
  headers: {
    "Apollo-Require-Preflight": "true",
    "Content-Type": "application/json",
    Authorization: !localStorage.getItem("riskcatch_user_token")
      ? ""
      : `Bearer ${localStorage.getItem("riskcatch_user_token")}`,
  },
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: `ws://${process.env.REACT_APP_SERVER_IP}`,
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

export function GlobalLoadingIndicator() {
  const status = useApolloNetworkStatus();

  if (status.numPendingMutations > 0) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          zIndex: "9999",
          position: "fixed",
          backgroundColor: "#999",
          opacity: "0.5",
        }}
        id="loadingTest"
      >
        <SyncLoader
          color="#36d7b7"
          height={100}
          width={100}
          style={{ position: "absolute", top: "50%", left: "50%" }}
        />
      </div>
    );
  } else {
    return null;
  }
}
const client = new ApolloClient({
  link: link.concat(splitLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
});

export default client;

const updateHeadersWithToken = (token) => {
  return setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "", // Include token in the authorization header
      },
    };
  });
};

const loginAfterToken = (token) => {
  // Store the token securely (e.g., in local storage)
  localStorage.setItem("riskcatch_user_token", token);

  // Update Apollo Client's headers with the token
  const authLink = updateHeadersWithToken(token);

  // Update the Apollo Client instance with the new headers
  client.setLink(authLink.concat(httpLink));

  // Optionally, you can fetch user data or perform other actions after login
};

export { loginAfterToken };

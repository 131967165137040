import React, { useCallback, useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import closeIcon from "../../../assets/icons/share/closeIcon.svg";
import { HugeText, LargeText } from "../../share/StyledText";
import { ThemeContext } from "../../../context/ThemeProvider";
import ApprovalSelfForm from "./ApprovalSelfForm";
//
import ApprovalLoadForm from "./ApprovalLoadForm";
import { CreateScarProvider } from "../../../pages/Project/CreateScar/CreateScarContainer";
import { useQuery } from "@apollo/client";
import { SEE_SCAR_MEMBER } from "../../../graphql/Project/CreateScar/query";
import { isEmail, isPhone } from "../../../utils/commons";

const Wrapper = styled.div`
  width: 100%;
  //max-width: 768px;
  max-width: 430px;
  height: calc(var(--vh, 1vh) * 100);
  max-height: 932px;
  position: fixed;
  top: 0;
  right: -100vw;
  z-index: 5;
  background-color: ${({ theme }) => theme.whiteColor};
  transition: all 0.4s;
  overflow: hidden;

  ${({ approvalSettingModal }) =>
    approvalSettingModal &&
    css`
      z-index: 10;
      top: 0;
      right: calc(100vw - 50%);
      transform: translateX(50%);
    `};
`;
const Header = styled.div`
  width: 100%;
  padding: ${({ theme }) => (theme.isApp ? "40px 20px 0" : "20px 20px 0")};
  background-color: ${({ theme }) => theme.bgColor};
  overflow: hidden;
`;
const Icon = styled.img`
  margin: 8px 0 24px;
`;
const ScrollBox = styled.div`
  height: calc(100vh - 158px);
  max-height: calc(932px - 158px);
  overflow: scroll;
`;
const PaddingBox = styled.div`
  padding: 20px;
`;
const TabBox = styled.div`
  width: calc(100% + 40px);
  display: flex;
  position: relative;
  left: -20px;
  margin-top: 26px;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  overflow: hidden;

  &:after {
    content: "";
    width: 50%;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: -1px;
    background-color: ${({ theme }) => theme.activeColor};
    transition: all 0.2s ease-in-out;

    ${({ active }) =>
      active &&
      css`
        left: 50%;
      `};
  }
`;
const TabMenu = styled(LargeText)`
  width: 50%;
  font-family: "AppleSDGothicNeoRegular";
  color: ${({ theme }) => theme.subTextColor};
  text-align: center;
  padding-bottom: 16px;

  ${({ active }) =>
    active &&
    css`
      font-family: "AppleSDGothicNeoBold";
      color: ${({ theme }) => theme.defaultTextColor};
    `};
`;

const ApprovalSettingModal = ({
  approvalSettingModal,
  setApprovalSettingModal,
}) => {
  const prjId = localStorage.getItem("prjId");
  const { language } = useContext(ThemeContext);
  const [tab, setTab] = useState("self");
  const [selected, setSelected] = useState(null);
  // 결재라인 직접입력하기
  const {
    name,
    setName,
    agree,
    setAgree,
    approvalList,
    setApprovalList,
    myProfile,
  } = useContext(CreateScarProvider); // Container Component 에서 상태 불러오기
  const [approvalLine, setApprovalLine] = useState({
    // 결제라인 이름
    name: "",
    // 결재라인 상태
    create: null,
    check: null,
    action: null,
    manager: null,
    actionCheck: null,
    finalSign: null,
    circulators: [],
  });
  const [country, setCountry] = useState("+82");
  const [group, setGroup] = useState("");
  const [circulatorSelect, setCirculatorSelect] = useState("phone");
  const [dist, setDist] = useState(""); // 회람자 상태
  const [buttonStatus, setButtonStatus] = useState(true); // 버튼 활성화 상태
  const [memberList, setMemberList] = useState([]);

  const { data: memberData, loading: memberLoading } = useQuery(
    SEE_SCAR_MEMBER,
    {
      variables: {
        prjId: Number(localStorage.getItem("prjId")),
      },
      fetchPolicy: "network-only",
    }
  );

  const findMyData = useCallback(
    (memberData) => {
      // 발행자 설정
      const findData = memberData?.seeScarMember?.prj_member.find(
        (data) => data?.user_id === myProfile?.user_id
      );
      if(findData && findData.user_id){        
        setApprovalLine({
          ...approvalLine,
          create: {
            id: findData.user_id,
            group: findData.user_changedPermission,
            name: findData.user_name,
            rank: findData.user_rank,
            countryCode: findData.user_countryCode,
            cellphone: findData.user_cellphone,
            email: findData.user_email,
            img: findData.user_profileImg,
          },
        });
      }
    },
    [myProfile, approvalLine]
  );

  useEffect(() => {
    if (!memberLoading) {
      findMyData(memberData);
    }
  }, [memberLoading]);

  const onChangeSelect = useCallback(
    (name, op) => {
      setApprovalLine({
        ...approvalLine,
        [name]: {
          id: op.user_id,
          group: op.user_changedPermission,
          name: op.user_name,
          rank: op.user_rank,
          countryCode: op.user_countryCode,
          cellphone: op.user_cellphone,
          email: op.user_email,
          img: op.user_profileImg,
        },
      });
    },
    [approvalLine]
  );

  const handleName = useCallback((name) => setName(name), []); // 결제라인 이름 설정

  const handleAgree = useCallback((length) => length === 5 ? alert('저장 가능한 결재라인은 최대 5개 입니다.'): setAgree(!agree), [agree]); // 결재라인 저장 동의하기
  const handleDeleteDist = useCallback(
    (value) => {
      // 회람자 삭제
      setApprovalLine({
        ...approvalLine,
        circulators: approvalLine.circulators.filter((item) => item !== value),
      });
    },
    [approvalLine]
  );

  const handleSetApprovalLine = useCallback(() => {
    // 결재라인 및 회람자 설정하기
    if (Object.values(approvalLine).includes(null)) {
      alert(
        (language === "Kor" && "모두 선택해주세요.") ||
          (language === "Eng" && "Please enter the all")
      );
      return;
    }
    if (agree && name.trim() === "") {
      alert(
        (language === "Kor" && "결제라인 이름을 입력해주세요.") ||
          (language === "Eng" && "Please enter a approval process name.")
      );
      return;
    }
    setApprovalList({
      ...approvalLine,
      name,
    }); // 회람자 추가하기
    setApprovalSettingModal();
  }, [approvalLine, agree, name]);

  const onBlurDist = useCallback(() => {
    // 회람자 입력값이 연락처라면 hyphen 추가
    if (isPhone(dist)) {
      setDist(dist);
    }
  }, [dist]);

  const handleCreateDist = useCallback(async () => {
    // 회람자 추가
    if (dist === "") {
      // 회람자 빈 값 입력시 경고
      alert(
        (language === "Kor" && "연락처 또는 메일을 입력해주세요.") ||
          (language === "Eng" && "Please Enter phone number or email")
      );
      return;
    }

    if (circulatorSelect === "phone") {
      let tmpDist = `${country} ${dist}`;

      if (approvalLine.circulators.includes(tmpDist)) {
        // 이미 추가된 회람자 일 경우 경고
        alert(
          (language === "Kor" && "이미 추가하신 회랍자입니다.") ||
            (language === "Eng" && "You have already added this Distribuition.")
        );
        setDist("");
        return;
      }
    } else {
      if (approvalLine.circulators.includes(dist)) {
        // 이미 추가된 회람자 일 경우 경고
        alert(
          (language === "Kor" && "이미 추가하신 회랍자입니다.") ||
            (language === "Eng" && "You have already added this Distribuition.")
        );
        setDist("");
        return;
      }
    }

    if (isEmail(dist) || isPhone(dist)) {
      // 이메일 또는 연락처일 경우에만
      if (circulatorSelect === "phone") {
        let tmpValue = `${country} ${dist}`;
        setApprovalLine({
          ...approvalLine,
          circulators: [...approvalLine.circulators, tmpValue],
        });
      } else {
        setApprovalLine({
          ...approvalLine,
          circulators: [...approvalLine.circulators, dist],
        });
      }
    } else {
      // 이메일 또는 연락처 형식이 아닐 경우 경고
      alert(
        (language === "Kor" && "올바르지 않는 메일 또는 연락처 형식입니다.") ||
          (language === "Eng" && "Invalid mail or phone number format")
      );
    }
    setDist("");
  }, [approvalLine.circulators, circulatorSelect, country, dist]);

  useEffect(() => {
    // 결재라인 모두 입력 시 버튼 활성화
    if (
      approvalLine.create &&
      approvalLine.check &&
      approvalLine.action &&
      approvalLine.manager &&
      approvalLine.actionCheck &&
      approvalLine.finalSign
    ) {
      setButtonStatus(false);
    } else {
      setButtonStatus(true);
    }
  }, [agree, approvalLine]);

  useEffect(() => {
    if (memberData?.seeScarMember?.result) {
      const tmpMemberList = memberData.seeScarMember.prj_member.filter(
        (member) => member.user_id !== 0
      );
      setMemberList(tmpMemberList);
    }
  }, [memberData]);

  useEffect(() => {
    if (!!approvalList) {
      setApprovalLine(approvalList);
    }
  }, [approvalList]);

  return (
    <Wrapper approvalSettingModal={approvalSettingModal}>
      <Header>
        <Icon src={closeIcon} onClick={() => setApprovalSettingModal(false)} />
        <HugeText>
          {language === "Kor" && "결재라인 설정하기"}
          {language === "Eng" && "Set Approval Process"}
        </HugeText>
        <TabBox active={tab === "load"}>
          <TabMenu onClick={() => setTab("self")} active={tab === "self"}>
            {language === "Kor" && "직접입력하기"}
            {language === "Eng" && "Set Process"}
          </TabMenu>
          <TabMenu onClick={() => setTab("load")} active={tab === "load"}>
            {language === "Kor" && "불러오기"}
            {language === "Eng" && "Load"}
          </TabMenu>
        </TabBox>
      </Header>

      {tab === "self" ? (
        <ScrollBox>
          <PaddingBox>
            <ApprovalSelfForm
              prjId={prjId}
              memberList={memberList}
              approvalLine={approvalLine}
              onChangeSelect={onChangeSelect}
              circulatorSelect={circulatorSelect}
              setCirculatorSelect={setCirculatorSelect}
              country={country}
              setCountry={setCountry}
              dist={dist}
              setDist={setDist}
              handleCreateDist={handleCreateDist}
              onBlurDist={onBlurDist}
              handleDeleteDist={handleDeleteDist}
              name={name}
              handleName={handleName}
              agree={agree}
              handleAgree={handleAgree}
              handleSetApprovalLine={handleSetApprovalLine}
              buttonStatus={buttonStatus}
            />
          </PaddingBox>
        </ScrollBox>
      ) : (
        <ScrollBox>
          <ApprovalLoadForm selected={selected} setSelected={setSelected} />
        </ScrollBox>
      )}
    </Wrapper>
  );
};

export default React.memo(ApprovalSettingModal);

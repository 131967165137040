import { gql } from "@apollo/client";

export const SEE_MY_PROFILE_INFO = gql`
  query seeMyProfileInfo {
    seeMyProfileInfo {
      result
      error
      myInfo {
        user_id
        user_createdAt
        user_name
        user_rank
        user_countryCode
        user_cellphone
        user_email
        user_emailAuth
        user_group
        user_groupCompany
        user_profileImg
        user_tosAgree
        user_tosAgreeDate
        user_privacyPolicyAgree
        user_privacyPolicyAgreeDate
        user_language
        user_headAuth
        user_stop
        user_pushNoti
        user_msgNoti
        user_emailNoti
        user_kakaoNoti
        user_withdrawalDate
        notification {
          notifi_id
          notifi_createdAt
          notifi_content
        }
      }
    }
  }
`;

import React, { useCallback } from "react";
import styled, { css } from "styled-components";
import ProjectDetailHeader from "../../../components/feature/Project/ProjectDetailHeader";
import { LargeText } from "../../../components/share/StyledText";
import SwiperMemberList from "../../../components/feature/Project/SwiperMemberList";
import ScarRadioButton from "../../../components/feature/Project/ScarRadioButton";
import ScarBriefInfoBox from "../../../components/feature/Project/ScarBriefInfoBox";
import StyledButton from "../../../components/share/StyledButton";
import { lightTheme } from "../../../styles/theme";
import searchIcon from "../../../assets/icons/project/search.svg";
import FlexBox from "../../../components/share/FlexBox";
import UserProfileModal from "../../../components/feature/Project/UserProfileModal";
import BottomSheet from "../../../components/BottomSheet/BottomSheet";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${({ theme }) =>
    theme.isApp ? "152px 20px 105px" : "132px 20px 105px"};
`;
const SortingForm = styled.div`
  margin: 13px 0 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
`;
const ButtonBox = styled(FlexBox)`
  margin: 16px 0 0;
  justify-content: space-between;
`;
const Image = styled.img`
  display: inline-block;
`;

const ScarButtonBox = styled.div`
  display: flex;
`;

const SortButton = styled.button`
  border: 1px solid #e6e6e6;
  border-radius: 15px;
  padding: 6px 12px;
  font-weight: bold;

  &::after {
    content: " >";
  }
`;

const Button = styled.button`
  border: 1px solid #e6e6e6;
  border-radius: 15px;
  padding: 12px 16px;
  border-radius: 5px;
  font-weight: bold;

  &:not(:first-of-type) {
    margin-top: 12px;
  }

  ${({ active }) =>
    active &&
    css`
      border: 1px solid #2564b1;
    `}
`;

const BottomSheetHeader = styled.div`
  position: relative;
  height: 36px;
`;

const BottomSheetContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const BottomSheetTitle = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-weight: bold;
  width: 100%;
  text-align: center;
`;

const BottomSheetClose = styled.button`
  font-size: 24px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  right: 32px;
  transform: translateY(-50%);
`;

const ProjectDetailPresenter = ({
  language = "Kor",
  navigate,
  prjInfo,
  scarInfo,
  prjId,
  prjDetailLoading,
  prjDetailData,
  myProfile,
  scarOrder,
  handleScarOrder,
  isOpen,
  handleModalClose,
  handleModalOpen,
}) => {
  const handleSortOrderLabel = useCallback(() => {
    if (scarOrder === "scar_number" && language === "Kor") return "문서번호순";
    if (scarOrder === "scar_number" && language === "Eng") return "by Number";
    if (scarOrder === "scar_createdAt" && language === "Kor") return "최신순";
    if (scarOrder === "scar_createdAt" && language === "Eng") return "by New";
    if (scarOrder === "scar_riskGrade" && language === "Kor")
      return "위험등급순";
    if (scarOrder === "scar_riskGrade" && language === "Eng")
      return "by Risk Code";
  }, [language, scarOrder]);

  if (prjDetailLoading) return null;

  return (
    <Wrapper>
      <ProjectDetailHeader prjInfo={prjInfo} prjId={prjId} />
      <ButtonBox>
        {prjDetailData?.seeProjectDetail?.scarCreatePermission &&
          prjDetailData?.seeProjectDetail?.project?.prj_status !== "end" && (
            <StyledButton
              title={
                (language === "Kor" && "SCAR 발행하기") ||
                (language === "Eng" && "Create SCAR")
              }
              width="99%"
              border={`1px solid ${lightTheme.activeColor}`}
              fontColor={lightTheme.activeColor}
              fontWeight={700}
              fontFamily="AppleSDGothicNeoBold"
              bgColor={lightTheme.whiteColor}
              onClick={() => navigate("/create-scar", { state: { prjId } })}
            />
          )}
        {/* {prjDetailData?.seeProjectDetail?.scarCreatePermission && (
          <StyledButton
            title={
              (language === "Kor" && "체크리스트") ||
              (language === "Eng" && "Check List")
            }
            width="48%"
            border={`1px solid ${lightTheme.activeColor}`}
            fontColor={lightTheme.activeColor}
            fontWeight={700}
            fontFamily="AppleSDGothicNeoBold"
            bgColor={lightTheme.whiteColor}
            onClick={() => navigate("/check-list", { state: { prjId } })}
          />
        )} */}
      </ButtonBox>
      <SwiperMemberList />

      <FlexBox alignItems="center" justifyContent="space-between">
        <LargeText>
          {language === "Kor" && "SCAR 발행 목록"}
          {language === "Eng" && "SCAR List"}
        </LargeText>
        <ScarButtonBox>
          <SortButton onClick={() => handleModalOpen()}>
            {handleSortOrderLabel()}
          </SortButton>
          <Image
            src={searchIcon}
            onClick={() => navigate("/scar-search", { state: prjId })}
          />
        </ScarButtonBox>
      </FlexBox>
      <SortingForm>
        <ScarRadioButton
          title={
            (language === "Kor" && "전체") || (language === "Eng" && "Total")
          }
          textAlign={language !== "Kor" && "left"}
          count={scarInfo?.scarStatusCount?.totalCount}
        />
        <ScarRadioButton
          title={
            (language === "Kor" && "진행중") ||
            (language === "Eng" && "In Progress")
          }
          textAlign={language !== "Kor" && "left"}
          count={scarInfo?.scarStatusCount?.inProgressCount}
        />
        <ScarRadioButton
          title={
            (language === "Kor" && "조치필요") ||
            (language === "Eng" && "Action\nRequired")
          }
          textAlign={language !== "Kor" && "left"}
          count={scarInfo?.scarStatusCount?.actionRequireCount}
        />
        <ScarRadioButton
          title={
            (language === "Kor" && "확인필요") ||
            (language === "Eng" && "Approval\nRequired")
          }
          textAlign={language !== "Kor" && "left"}
          count={scarInfo?.scarStatusCount?.confirmRequireCount}
        />
        <ScarRadioButton
          title={
            (language === "Kor" && "불가 / 반려") ||
            (language === "Eng" && "Rejected")
          }
          textAlign={language !== "Kor" && "left"}
          count={scarInfo?.scarStatusCount?.rejectCount}
        />
        <ScarRadioButton
          title={
            (language === "Kor" && "최종완료") ||
            (language === "Eng" && "Closed")
          }
          textAlign={language !== "Kor" && "left"}
          count={scarInfo?.scarStatusCount?.finalCount}
        />
      </SortingForm>
      {scarInfo?.scarList?.map((scar, index) => (
        <ScarBriefInfoBox
          key={index}
          scar={scar}
          isOwnScar={scar?.signLine?.create_id === myProfile?.user_id}
        />
      ))}
      {/* // 구성원 프로필 */}
      <UserProfileModal />

      {isOpen ? (
        <BottomSheet closeBottomSheet={handleModalClose}>
          <BottomSheetHeader>
            <BottomSheetTitle>
              {(language === "Kor" && "정렬") || (language === "Eng" && "Sort")}
            </BottomSheetTitle>
            <BottomSheetClose onClick={() => handleModalClose()}>
              X
            </BottomSheetClose>
          </BottomSheetHeader>
          <BottomSheetContent>
            <Button
              active={scarOrder === "scar_number"}
              onClick={() => handleScarOrder("scar_number")}
            >
              {language === "Kor" && "문서번호순"}
              {language === "Eng" && "by Number"}
            </Button>
            <Button
              active={scarOrder === "scar_createdAt"}
              onClick={() => handleScarOrder("scar_createdAt")}
            >
              {language === "Kor" && "최신순"}
              {language === "Eng" && "by New"}
            </Button>
            <Button
              active={scarOrder === "scar_riskGrade"}
              onClick={() => handleScarOrder("scar_riskGrade")}
            >
              {language === "Kor" && "위험등급순"}
              {language === "Eng" && "by Risk Grade"}
            </Button>
          </BottomSheetContent>
        </BottomSheet>
      ) : null}
    </Wrapper>
  );
};

export default React.memo(ProjectDetailPresenter);

import React, { useContext, useState } from "react";
import ApprovalProcessViewPresenter from "./ApprovalProcessViewPresenter";
import { ThemeContext } from "../../../context/ThemeProvider";
import { useLocation } from "react-router-dom";

const ApprovalProcessViewContainer = () => {
  const { language } = useContext(ThemeContext);
  const location = useLocation();
  const [signLine, setSignLine] = useState(location.state?.signLine || {});
  const [scarStatus , setScarStatus ] = useState(location.state?.scarStatus || '' );
  
  const changeSignLine = (name, op) => {
    setSignLine((prev) => {
      return {
        ...prev,
        [`${name}_activate`]: true,
        [`${name}_cellphone`]: op?.cellphone,
        [`${name}_countryCode`]: op?.countryCode,
        [`${name}_email`]: op?.email,
        [`${name}_group`]: op?.group,
        [`${name}_id`]: op?.id,
        [`${name}_img`]: op?.img,
        [`${name}_name`]: op?.name,
        [`${name}_rank`]: op?.rank,
      };
    });
  };

  return (
    <>
    {scarStatus && 
    <ApprovalProcessViewPresenter
      language={language}
      signLine={signLine}
      changeSignLine={changeSignLine}
      memberList={location.state?.memberList || []}
      isOwnScar={!!location.state?.isOwnScar}
      scarStatus={scarStatus}
    />
    }
    </>
  );
};

export default ApprovalProcessViewContainer;

import styled from "styled-components";

export default styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) =>
    flexDirection ? flexDirection : "row"};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "flex-start")};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "flex-start"};
  margin: ${({ margin }) => (margin ? margin : 0)};
  gap: ${({ gap = 0 }) => `${gap}px`};
  //flex-wrap: ${({ flexWrap }) => (flexWrap ? flexWrap : "nowrap")};
  //border: 1px solid yellowgreen;
`;

import React, { useCallback, useContext } from "react";
import MyProfilePresenter from "./MyProfilePresenter";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeProvider";
import { ProfileContext } from "../../../App";
import {
  EDIT_MY_PROFILE_IMG,
  WITHDRAWAL,
} from "../../../graphql/myPage/MyProfile/mutation";
import { useMutation } from "@apollo/client";
import { getExtension } from "../../../utils/commons";
import { ALLOW_FILE_EXTENSION } from "../../../utils/constants";
import { AuthContext } from "../../../context/AuthContext";

const MyProfileContainer = () => {
  const { myProfile, profileRefetch } = useContext(ProfileContext);
  const navigate = useNavigate();
  const { language } = useContext(ThemeContext);
  const { userLogout } = useContext(AuthContext);

  const [editMyProfileImg] = useMutation(EDIT_MY_PROFILE_IMG);
  const [withdrawal] = useMutation(WITHDRAWAL);

  const onChangeImage = useCallback(
    async (e) => {
      // 이미지 등록 함수
      const file = e.target.files[0];
      const ext = getExtension(file.name);

      if (file.size / 1024 / 1024 > 10) {
        alert(
          (language === "Kor" && "이미지 파일은 10MB 이하로 업로드해주세요.") ||
            (language === "Eng" && "Please upload the image file under 10MB."),
        );
        return;
      }

      if (!(ALLOW_FILE_EXTENSION.indexOf(ext) > -1) || ext === "") {
        alert(
          (language === "Kor" && "이미지 파일만 업로드 가능합니다.") ||
            (language === "Eng" && "Only image files can be uploaded"),
        );
        return;
      }

      try {
        const { data } = await editMyProfileImg({
          variables: {
            user_profileImg: file,
          },
        });
        if (data?.editMyProfileImg?.result) {
          await profileRefetch();
          alert(
            (language === "Kor" && "프로필 사진을 수정하였습니다.") ||
              (language === "Eng" && "Modified profile picture successfully"),
          );
        }
        if (data?.editMyProfileImg?.error !== "") {
          alert(data?.editMyProfileImg?.error);
        }
      } catch (e) {
        alert(e.message);
      }
    },
    [language],
  );

  const lookUpTerm = useCallback((url) => {
    if (window.ReactNativeWebView) {
      const data = {
        type: "WINDOW_OPEN",
        payload: url,
      };
      window.ReactNativeWebView.postMessage(JSON.stringify(data));
    } else {
      window.open(url, "_blank");
    }
  }, []);

  const handleWithdrawal = useCallback(
    async (e) => {
      try {
        if (
          !window.confirm(
            (language === "Kor" &&
              "정말 탈퇴하시겠습니까?\nSCAR 발행 및 승인에 대한 이력정보는 탈퇴 후 5년간 보관됩니다.") ||
              (language === "Eng" &&
                "Would you like to withdraw from the membership?\\nThe history information on SCAR issuance and approval will be kept for 5 years after withdrawal."),
          )
        ) {
          return;
        }

        const {
          data: {
            withdrawal: { result },
          },
        } = await withdrawal();

        if (result) {
          alert(
            (language === "Kor" && "회원탈퇴 성공") ||
              (language === "Eng" && "Success withdrawal"),
          );
          userLogout(false);
        } else {
          alert(
            (language === "Kor" && "회원탈퇴 실패") ||
              (language === "Eng" && "Fail withdrawal"),
          );
        }
      } catch (e) {
        alert(
          (language === "Kor" && "회원탈퇴 실패") ||
            (language === "Eng" && "Fail withdrawal"),
        );
      }
    },
    [language, userLogout, withdrawal],
  );

  return (
    <MyProfilePresenter
      navigate={navigate}
      language={language}
      onChangeImage={onChangeImage}
      myProfile={myProfile}
      lookUpTerm={lookUpTerm}
      handleWithdrawal={handleWithdrawal}
    />
  );
};

export default MyProfileContainer;

import React from "react";
import styled from "styled-components";
import GoBack from "../../../components/share/GoBack";
import FlexBox from "../../../components/share/FlexBox";
import ProfileUpload from "../../../components/share/ProfileUpload";
import {
  HugeText,
  LargeText,
  MediumText,
  SmallText,
} from "../../../components/share/StyledText";
import pencilIcon from "../../../assets/icons/myPage/pencilIcon.svg";
import rightArrow from "../../../assets/icons/myPage/rightArrow.svg";
import { groupChanger } from "../../../lib/ProfileUtils";
import { dateFormat } from "../../../utils/commons";

const Wrapper = styled.div`
  flex: 1;
  padding: ${({ theme }) => (theme.isApp ? "40px 20px" : "20px")};
`;
const Header = styled.div`
  position: sticky;
  height: 160px;

  &:before {
    content: "";
    width: calc(100% + 40px);
    height: 190px;
    position: absolute;
    top: -50px;
    left: -20px;
    z-index: -1;
    background-color: ${({ theme }) => theme.lightBorderColor};
  }
`;
const Title = styled(LargeText)`
  font-family: "AppleSDGothicNeoBold";
`;
const EllipsisText = styled(HugeText)`
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & + & {
    margin-left: 6px;
  }
`;
const MailText = styled(LargeText)`
  color: ${({ theme }) => theme.subTextColor};
  margin-top: 4px;
`;
const Contents = styled.div`
  position: relative;
  border: 1px solid ${({ theme }) => theme.bgColor};
  margin-bottom: 26px;

  &:after {
    content: "";
    width: calc(100% + 40px);
    position: absolute;
    bottom: 0;
    left: -20px;
    border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  }
`;
const ActiveText = styled.div`
  color: ${({ theme }) => theme.activeColor};
`;
const Icon = styled.img`
  margin: ${({ margin }) => (margin ? margin : "0 0 0 6px")};
`;
const InfoBox = styled.div`
  margin-bottom: 24px;
`;
const GrayText = styled(SmallText)`
  color: ${({ theme }) => theme.subTextColor};
  margin: ${({ margin }) => (margin ? margin : "0 0 14px")};
`;
const TermText = styled(MediumText)`
  font-size: 18px;
`;

const MyProfilePresenter = ({
  language = "Kor",
  navigate,
  myProfile,
  onChangeImage,
  lookUpTerm,
  handleWithdrawal,
}) => {
  return (
    <Wrapper>
      <Header>
        <GoBack route="/project-list" />
        <FlexBox alignItems="center">
          <ProfileUpload
            BORDER
            imgUrl={myProfile?.user_profileImg}
            onChange={onChangeImage}
          />
          <FlexBox flexDirection="column" margin="0 0 0 16px">
            <FlexBox alignItems="center">
              <EllipsisText>{myProfile?.user_name}</EllipsisText>
              <EllipsisText>{myProfile?.user_rank}</EllipsisText>
            </FlexBox>
            <MailText>{myProfile?.user_email}</MailText>
          </FlexBox>
        </FlexBox>
      </Header>

      <Contents>
        <FlexBox
          alignItems="center"
          justifyContent="space-between"
          margin="0 0 26px"
        >
          <Title>
            {language === "Kor" && "내정보"}
            {language === "Eng" && "My info"}
          </Title>
          <FlexBox
            alignItems="center"
            onClick={() => navigate("/edit-my-profile")}
          >
            <ActiveText>
              {language === "Kor" && "내 정보 수정하기"}
              {language === "Eng" && "Modify my info"}
            </ActiveText>
            <Icon src={pencilIcon} margin="0 0 0 12px" />
          </FlexBox>
        </FlexBox>

        <InfoBox>
          <GrayText>
            {language === "Kor" && "이름과 직책"}
            {language === "Eng" && "Name and position"}
          </GrayText>
          <MediumText>
            {myProfile?.user_name} {myProfile?.user_rank}
          </MediumText>
        </InfoBox>

        <InfoBox>
          <GrayText>
            {language === "Kor" && "소속 및 소속회사명"}
            {language === "Eng" && "Affiliated and affiliated company name"}
          </GrayText>
          <MediumText>
            {groupChanger(myProfile?.user_group)} {myProfile?.user_groupCompany}
          </MediumText>
        </InfoBox>

        <InfoBox
          onClick={() =>
            navigate("/edit-email", { state: { mail: myProfile?.user_email } })
          }
        >
          <GrayText>
            {language === "Kor" && "메일주소"}
            {language === "Eng" && "Email"}
          </GrayText>
          <FlexBox alignItems="center" justifyContent="space-between">
            <MediumText>{myProfile?.user_email}</MediumText>
            <FlexBox alignItems="center">
              <ActiveText>
                {language === "Kor" &&
                  (myProfile?.user_emailAuth ? "인증완료" : "인증하기")}
                {language === "Eng" &&
                  (myProfile?.user_emailAuth
                    ? "Auth completed"
                    : "Auth not completed")}
              </ActiveText>
              <Icon margin="0 0 4px 6px" src={rightArrow} />
            </FlexBox>
          </FlexBox>
        </InfoBox>
      </Contents>

      <Title>
        {language === "Kor" && "약관"}
        {language === "Eng" && "My info"}
      </Title>

      <FlexBox
        alignItems="center"
        justifyContent="space-between"
        margin="28px 0 30px"
        onClick={() => lookUpTerm("https://agreementshg.riskzero.ai/policy")}
      >
        <TermText>
          {language === "Kor" && "개인정보처리방침"}
          {language === "Eng" && "Personal information processing policy"}
        </TermText>
        <FlexBox alignItems="center">
          <GrayText margin="0">
            {dateFormat(
              new Date(Number(myProfile?.user_privacyPolicyAgreeDate)),
              "yyyy.MM.dd",
            )}
          </GrayText>
          <Icon src={rightArrow} />
        </FlexBox>
      </FlexBox>

      <FlexBox
        alignItems="center"
        justifyContent="space-between"
        margin="28px 0 30px"
        onClick={() => lookUpTerm("https://agreementshg.riskzero.ai/service")}
      >
        <TermText>
          {language === "Kor" && "서비스이용약관"}
          {language === "Eng" && "Terms and Conditions of Service"}
        </TermText>
        <FlexBox alignItems="center">
          <GrayText margin="0">
            {dateFormat(myProfile?.user_tosAgreeDate, "yyyy.MM.dd")}
          </GrayText>
          <Icon src={rightArrow} />
        </FlexBox>
      </FlexBox>
      <Title>
        {language === "Kor" && "기타"}
        {language === "Eng" && "Etc"}
      </Title>
      {/* <FlexBox
        alignItems="center"
        justifyContent="space-between"
        margin="28px 0 30px"
        onClick={handleWithdrawal}
      >
        <TermText>
          {language === "Kor" && "회원탈퇴"}
          {language === "Eng" && "Withdrawal"}
        </TermText>
        <FlexBox alignItems="center">
          <Icon src={rightArrow} />
        </FlexBox>
      </FlexBox> */}
    </Wrapper>
  );
};

export default React.memo(MyProfilePresenter);

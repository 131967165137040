import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import CreateScarPresenter from "./CreateScarPresenter";
import { ThemeContext } from "../../../context/ThemeProvider";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import {
  SEE_SCAR_LABEL_LIST,
  SEE_SAVE_SIGN_LINE,
} from "../../../graphql/Project/CreateScar/query";
import { CREATE_SCAR } from "../../../graphql/Project/CreateScar/mutation";
import {
  engGradeChanger,
  engLabelChanger,
  gradeChanger,
} from "../../../lib/ProjectUtils";
import { ProfileContext } from "../../../App";
import { SEE_PROJECT_DETAIL } from "../../../graphql/Project/ProjectDetail/query";
import { SEE_SCAR_LIST } from "../../../graphql/Project/ProjectDetail/query";
import { ALLOW_FILE_EXTENSION } from "../../../utils/constants";
import { getExtension } from "../../../utils/commons";
import axios from "axios";

export const CreateScarProvider = createContext(null);

const CreateScarContainer = () => {
  const { language } = useContext(ThemeContext);
  const { myProfile , profileRefetch} = useContext(ProfileContext);
      
  const prjId = Number(localStorage.getItem("prjId"));
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    // input 상태
    violation: "",
    location: "",
    label: language === "Kor" ? "라벨 선택하기" : "Select Label",
    labels: [],
    riskImpact: language === "Kor" ? "상" : "High",
    riskCode: "",
    riskCodes: [],
  });
  const [scarImages, setScarImages] = useState([]); // SCAR 이미지 배열
  const [scarThumbnail, setScarThumbnail] = useState([]);
  const [buttonStatus, setButtonStatus] = useState(true); // SCAR 발행 버튼 활성 상태
  const [riskCodeModal, setRiskCodeModal] = useState(false); // 리스크코드 등록 모달
  const [approvalSettingModal, setApprovalSettingModal] = useState(false); // 결재라인 설정 모달
  const [name, setName] = useState(""); // 결재라인 이름
  const [agree, setAgree] = useState(false); // 결재라인 저장 상태
  const [approvalList, setApprovalList] = useState(null); // 결재라인
  const [labelList, setLabelList] = useState([]);
  const [createScarLoading, setCreateScarLoading] = useState(false);

  const { data: labelData } = useQuery(SEE_SCAR_LABEL_LIST, {
    variables: {
      lb_language: (language || "").toLowerCase(),
    },
  });
  const { refetch: signLineRefetch } = useQuery(SEE_SAVE_SIGN_LINE, {
    variables: { prjId },
  });
  const { refetch: scarListRefetch } = useQuery(SEE_SCAR_LIST, {
    variables: {
      prjId:
        localStorage.getItem("prjId") === null
          ? 0
          : Number(localStorage.getItem("prjId")),
      searchTerm: "",
      statusFilter: "total",
    },
  });

  const { refetch: prjDetailRefetch } = useQuery(SEE_PROJECT_DETAIL, {
    variables: { prjId },
    fetchPolicy: "network-only",
  });

  const [createScar] = useMutation(CREATE_SCAR);

  const onChangeInput = useCallback(
    (e) => {
      // input 상태 변경 함수
      const { name, value } = e.target;

      if (value.substring(0, 1) === " ") return;

      if (name === "label") {
        // 라벨 선택일 경우 라벨 배열 추가
        let tmpLabels = [...inputs.labels];
        tmpLabels.push(value);

        if (inputs.labels.includes(value)) {
          language === "Kor"
            ? alert("이미 추가하신 라벨입니다.")
            : alert("The label you have already added.");
          return;
        }

        setInputs({
          ...inputs,
          label: value,
          labels: tmpLabels,
        });
      } else {
        setInputs({
          ...inputs,
          [name]: value,
        });
      }
    },
    [inputs],
  );

  const handleDeleteLabel = useCallback(
    (item) => {
      // 라벨 삭제
      setInputs({
        ...inputs,
        labels: inputs.labels.filter((chip) => chip !== item),
      });
    },
    [inputs],
  );

  const onChangeImage = useCallback(
    async (e) => {
      // 이미지 등록 함수
      let files = e.target.files;

      if (files.length < 1) return;

      if (scarImages.length + files.length > 5) {
        alert(
          (language === "Kor" && "최대 5장까지 업로드할 수 있습니다.") ||
            (language === "Eng" && "You can upload up to 5 images"),
        );
        files = null;
        return;
      }

      for (let i = 0; i < files.length; i++) {
        if (files[i].size / 1024 / 1024 > 10) {
          alert(
            (language === "Kor" &&
              "이미지 파일은 10MB 이하로 업로드해주세요.") ||
              (language === "Eng" && "Please upload the image file under 10MB"),
          );
          return;
        }

        let ext = getExtension(files[i].name);

        if (!(ALLOW_FILE_EXTENSION.indexOf(ext) > -1) || ext === "") {
          alert(
            (language === "Kor" && "이미지 파일만 업로드 가능합니다.") ||
              (language === "Eng" && "Only image files can be uploaded"),
          );
          return;
        }
      }

      setScarImages([...scarImages, ...files]); // 뮤테이션 보낼 데이터

      //여기부턴 썸네일
      let thumbnailImgList = [...scarThumbnail];

      for (let i = 0; i < files.length; i++) {
        thumbnailImgList.push(URL.createObjectURL(files[i]));
      }
      setScarThumbnail(thumbnailImgList);
    },
    [scarThumbnail, language, scarImages, scarThumbnail],
  );

  const handleDeleteImage = useCallback(
    (index) => {
      scarThumbnail.splice(index, 1);
      //   Array.prototype.splice(scarImages, index, 2);
      setScarThumbnail([...scarThumbnail]);
      setScarImages(scarImages.filter((el) => scarImages[index] !== el));
    },
    [scarImages, scarThumbnail],
  );

  const handleCreateScar = useCallback(async () => {
    // 스카 생성
    if (approvalList.length < 6) {
      (language === "Kor" && alert("결재라인을 설정해주세요.")) ||
        (language === "Eng" && alert("Please set the approval line."));
      return;
    }

    setCreateScarLoading(true);

    try {
      let formData = new FormData();
      formData.append("prjId", prjId);
      formData.append("scarTitle", inputs.violation);
      formData.append("scarSpot", inputs.location);
      formData.append(
        "scarRiskGrade",
        language === "Kor"
          ? gradeChanger(inputs.riskImpact)
          : engGradeChanger(inputs.riskImpact),
      );
      formData.append("scarRiskcode", inputs.riskCode);
      formData.append(
        "scarLabel",
        language === "Kor"
          ? inputs.labels
          : inputs.labels.map((label) => engLabelChanger(label)),
      );
      Object.entries(approvalList).forEach(
        ([approvalListKey, approvalListValue]) => {
          if (typeof approvalListValue === "object") {
            Object.entries(approvalListValue).forEach(
              ([approvalItemKey, approvalItemValue]) => {
                formData.append(
                  `signLine[${approvalListKey}][${approvalItemKey}]`,
                  approvalItemValue,
                );
              },
            );
          } else {
            formData.append(`signLine[${approvalListKey}]`, approvalListValue);
          }
        },
      );
      // formData.append("signLine", approvalList);
      formData.append("saveSignLine", agree);

      if (!scarImages.length) {
        formData.append("scarImgSelect", false);
      } else {
        formData.append("scarImgSelect", true);

        scarImages.forEach((scarImage, index) => {
          formData.append(`scarImgs`, scarImage);
        });
      }      
      
      const { data } = await axios.post(
        `//${process.env.REACT_APP_API_URL}/createScar`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "riskcatch_user_token",
            )}`,
            "Content-Type": "multipart/form-data",
          },
        },
      )

      if (data?.result) {
        alert(
          (language === "Kor" && "스카를 발행했습니다.") ||
            (language === "Eng" && "Issued SCAR"),
        );
        await prjDetailRefetch();
        await signLineRefetch();
        navigate("/project-detail");
        await scarListRefetch();
        setCreateScarLoading(false);
      }

      if (data?.error !== "") {
        alert(data?.error);
      }
    } catch (e) {
      alert(JSON.stringify(e));
    } finally {
      setCreateScarLoading(false);
      navigate("/project-detail");
    }
  }, [inputs, scarImages, approvalList, agree, name]);

  const handleSelectRiskCode = useCallback(
    (code) => {
      // 리스크 코드 선택
      setInputs({
        ...inputs,
        riskCode: code,
      });
      setRiskCodeModal(false);
    },
    [inputs],
  );

  useEffect(() => {
    if (
      inputs.violation !== "" &&
      inputs.labels.length !== 0 &&
      !!approvalList &&
      scarImages.length > 0
    ) {
      // 필수 정보 모두 입력 시 버튼 활성화
      setButtonStatus(false);
    } else {
      setButtonStatus(true);
    }
  }, [inputs, approvalList, scarImages]);

  useEffect(() => {
    if (labelData?.seeScarLabelList?.result) {
      setLabelList(
        labelData.seeScarLabelList.labelList.map((label) => label.lb_name),
      );
    }
  }, [labelData]);

  useEffect(() => {
    profileRefetch();
  },[])

  return (
    <CreateScarProvider.Provider
      value={{
        handleSelectRiskCode,
        agree,
        setAgree,
        approvalList,
        setApprovalList,
        setApprovalSettingModal,
        name,
        setName,
        prjId,
        myProfile,
      }}
    >
      <CreateScarPresenter
        language={language}
        inputs={inputs}
        onChangeInput={onChangeInput}
        handleDeleteLabel={handleDeleteLabel}
        riskCodeModal={riskCodeModal}
        setRiskCodeModal={setRiskCodeModal}
        approvalSettingModal={approvalSettingModal}
        setApprovalSettingModal={setApprovalSettingModal}
        approvalList={approvalList}
        onChangeImage={onChangeImage}
        handleDeleteImage={handleDeleteImage}
        buttonStatus={buttonStatus}
        handleCreateScar={handleCreateScar}
        labelList={labelList}
        scarThumbnail={scarThumbnail}
        myProfile={myProfile}
        createScarLoading={createScarLoading}
      />
    </CreateScarProvider.Provider>
  );
};

export default CreateScarContainer;

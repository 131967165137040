import React, { useCallback, useContext, useEffect, useState } from "react";
import CreateProjectPresenter from "./CreateProjectPresenter";
import { ThemeContext } from "../../../context/ThemeProvider";
import { useNavigate } from "react-router-dom";
import { ALLOW_FILE_EXTENSION } from "../../../utils/constants";
import { getExtension, isEmail, isEmptyObj } from "../../../utils/commons";

const CreateProjectContainer = () => {
  const navigate = useNavigate();

  const { language } = useContext(ThemeContext); // 테마 모드
  const [nonDisclosure, setNonDisclosure] = useState(false); // 프로젝트 공개 여부
  const [postModal, setPostModal] = useState(false); // 주소 모달 상태
  const [projectInfo, setProjectInfo] = useState({
    name: "",
    address: "",
    detailAddress: "",
    period: [new Date(), new Date()],
    documentNumber: "",
  });
  const [imgUrl, setImgUrl] = useState(null);
  const [imgFile, setImgFile] = useState(null);
  const [buttonStatus, setButtonStatus] = useState(true); // 구성원 추가 버튼 활성 상태

  const goBack = useCallback(() => {
    navigate("/project-list");
    localStorage.removeItem("projectInfo");
  }, []);

  const onChangeInput = useCallback(
    
    (e) => {
      // input 상태 관리 함수
      
      const { name, value } = e.target;
      
      if (value.substring(0, 1) === " ") return;
      var special_pattern = /[`~!@#$%^&*|\\\'\";:\/?]/gi;
      if(special_pattern.test(value) == true){
        alert(
          (language === "KOR" && "특수문자 입력은 불가합니다.") ||
            (language === "ENG" && "Please Remove Special Character"),
        );
        return false; 
      }

      setProjectInfo({
        ...projectInfo,
        [name]: value,
      });
    },
    [projectInfo],
  );

  const onChangePeriod = useCallback(
    (name, value) => {
      // 기간 변경 함수
      setProjectInfo({
        ...projectInfo,
        [name]: value,
      });
    },
    [projectInfo],
  );

  const onChangeImage = useCallback(
    async (e) => {
      // 이미지 등록 함수
      const file = e.target.files[0];
      const reader = new FileReader();
      const ext = getExtension(file.name);

      if (file.size / 1024 / 1024 > 10) {
        alert(
          (language === "Kor" && "이미지 파일은 10MB 이하로 업로드해주세요.") ||
            (language === "Eng" && "Please upload the image file under 10MB."),
        );
        return;
      }

      if (!(ALLOW_FILE_EXTENSION.indexOf(ext) > -1) || ext === "") {
        alert(
          (language === "Kor" && "이미지 파일만 업로드 가능합니다.") ||
            (language === "Eng" && "Only image files can be uploaded"),
        );
        return;
      }

      setImgFile(file);
      try {
        reader.onload = () => {
          // Preview Image reader
          setImgUrl(reader.result);
        };
        reader.readAsDataURL(file);
      } catch (e) {
        console.info(e);
      }
    },
    [language],
  );

  const handleCompletePost = useCallback(
    (address) => {
      // 주소 api 완료 함수
      setProjectInfo({
        ...projectInfo,
        address,
      });
      setPostModal(false);
    },
    [projectInfo],
  );

  const handleAddMembers = useCallback(() => {
    const tmpProjectInfo = {
      ...projectInfo,
      imgFile,
      nonDisclosure,
    };

    navigate("/add-members", {
      // 구성원 추가 페이지로 이동 및 프로젝트 data 전달
      state: { projectInfo: tmpProjectInfo },
    });
    localStorage.setItem("projectInfo", JSON.stringify(tmpProjectInfo));
  }, [projectInfo, imgUrl, nonDisclosure]);

  useEffect(() => {
    const { name, address, period, documentNumber } = projectInfo;

    if (
      // 필수 정보 모두 입력시 버튼 활성화
      name !== "" &&
      address !== "" &&
      period[0] < period[1] &&
      documentNumber !== "" &&
      imgFile !== null &&
      !isEmptyObj(imgFile)
    ) {
      setButtonStatus(false);
    }
  }, [projectInfo, imgFile]);

  useEffect(() => {
    const localStorageInfo = JSON.parse(localStorage.getItem("projectInfo"));

    if (localStorageInfo) {
      setProjectInfo({
        name: localStorageInfo.name,
        address: localStorageInfo.address,
        detailAddress: localStorageInfo.detailAddress,
        period: [
          new Date(localStorageInfo.period[0]),
          new Date(localStorageInfo.period[1]),
        ],
        documentNumber: localStorageInfo.documentNumber,
      });
      setImgFile(localStorageInfo.imgFile);
      setNonDisclosure(localStorageInfo.nonDisclosure);
    }
  }, []);

  return (
    <CreateProjectPresenter
      language={language}
      goBack={goBack}
      nonDisclosure={nonDisclosure}
      setNonDisclosure={setNonDisclosure}
      postModal={postModal}
      setPostModal={setPostModal}
      handleCompletePost={handleCompletePost}
      projectInfo={projectInfo}
      onChangeInput={onChangeInput}
      onChangePeriod={onChangePeriod}
      imgUrl={imgUrl}
      onChangeImage={onChangeImage}
      buttonStatus={buttonStatus}
      handleAddMembers={handleAddMembers}
    />
  );
};

export default CreateProjectContainer;
